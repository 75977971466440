@tailwind base;
@tailwind components;
@tailwind utilities;

Canvas,
html {
  width: 100%;
  height: 100vh;
}

body {
  color: #2a2354;
}
